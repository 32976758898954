<template>
  <section>
    <div class="module fx computer">
      <div class="module_class">
        <div class="module_header fa">
          <img
            src="../../assets/images/class_back.png"
            class="module_back"
            alt=""
          />
          <div class="module_data">
            <div class="module_title">产品中心</div>
            <div class="module_en">PRODUCT</div>
          </div>
        </div>
        <div
          v-for="(item, index) in classList"
          :key="index"
          @click="parentClick(index)"
        >
          <div class="module_text">
            <a-icon v-if="!item.status" type="caret-right" />
            <a-icon v-if="item.status" type="caret-down" />
            <span v-show="$i18n.locale == 'zh'">{{ item.name }}</span>
            <span v-show="$i18n.locale == 'en'">{{ item.name_en }}</span>
          </div>
          <div>
            <div
              v-show="item.status"
              v-for="(itemS, indexS) in item.son"
              :key="indexS"
              class="module_child"
              :class="itemS.status ? 'module_active' : ''"
              @click.stop="childrenClick(index, indexS)"
            >
              <span v-show="$i18n.locale == 'zh'">{{ itemS.name }}</span>
              <span v-show="$i18n.locale == 'en'">{{ itemS.name_en }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="f1">
        <div v-if="!detailStatus" class="fx wp">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            @click="detailClick(item.id)"
            class="module_item"
          >
            <img class="module_image" :src="item.image" alt="" />
            <div class="module_footer fx">
              <div style="overflow: hidden; margin-right: 10px" class="f1 h1">
                <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
              </div>
              <div>{{ $t("language.detailTitle") }}→</div>
            </div>
          </div>
        </div>
        <div v-if="detailStatus" class="detail">
          <a-icon @click="detailClick" class="detail_back" type="arrow-left" />
          <div class="fx">
            <img :src="dataInfo.image" class="detail_image" alt="" />
            <div>
              <div class="detail_title">
                <span v-show="$i18n.locale == 'zh'">{{ dataInfo.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{
                  dataInfo.title_en
                }}</span>
              </div>
              <div class="detail_text">
                <div
                  v-show="$i18n.locale == 'zh'"
                  v-html="dataInfo.content"
                ></div>
                <div
                  v-show="$i18n.locale == 'en'"
                  v-html="dataInfo.content_en"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!detailStatus" class="page faj">
          <div class="fx">
            <div
              v-for="(item, index) in total"
              :key="index"
              class="page_item faj"
              @click="changePage(index + 1)"
              :class="p == index + 1 ? 'page_active' : ''"
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="phone">
      <div class="classP">
        <div class="fa wp">
          <div
            v-for="(item, index) in classList"
            :key="index"
            @click="parentPClick(index)"
            class="classP_table faj"
            :class="item.status ? 'classP_active' : ''"
          >
            <span v-show="$i18n.locale == 'zh'">{{ item.name }}</span>
            <span v-show="$i18n.locale == 'en'">{{ item.name_en }}</span>
          </div>
        </div>
        <div v-if="classList != ''" class="childP fx wp">
          <div
            v-for="(item, index) in classList[this.tablePindex].son"
            :key="index"
            @click="childrenPClick(item.id, index)"
            class="childP_item"
            :class="childPindex == index ? 'childP_active' : ''"
          >
            <span v-show="$i18n.locale == 'zh'">{{ item.name }}</span>
            <span v-show="$i18n.locale == 'en'">{{ item.name_en }}</span>
          </div>
        </div>
      </div>
      <div v-if="!detailStatus" class="moduleP fx wp">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          @click="detailClick(item.id)"
          class="moduleP_item"
        >
          <img :src="item.image" class="moduleP_image" alt="" />
          <div class="moduleP_footer h1">
            <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
            <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
          </div>
        </div>
      </div>
      <div v-if="detailStatus" class="detailP">
        <a-icon @click="detailClick" class="detailP_back" type="arrow-left" />
        <div>
          <img :src="dataInfo.image" class="detailP_image" alt="" />
          <div>
            <div class="detailP_title">
              <span v-show="$i18n.locale == 'zh'">{{ dataInfo.title }}</span>
              <span v-show="$i18n.locale == 'en'">{{ dataInfo.title_en }}</span>
            </div>
            <div v-show="$i18n.locale == 'zh'" class="detail_text"><div v-html="dataInfo.content"></div></div>
            <div v-show="$i18n.locale == 'en'" class="detail_text"><div v-html="dataInfo.content_en"></div></div>
          </div>
        </div>
      </div>
      <div v-if="!detailStatus" class="page faj">
        <div class="fx">
          <div
            v-for="(item, index) in total"
            :key="index"
            class="page_item faj"
            @click="changePage(index + 1)"
            :class="p == index + 1 ? 'page_active' : ''"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import qs from "qs";
import {
  get_item_category,
  get_item_list,
  get_item_info,
} from "@/assets/api/api";
export default {
  data() {
    return {
      detailStatus: false,
      classList: "",
      dataList: "",
      dataInfo: "",
      p: 1,
      total: "",

      tablePindex: 0,
      childPindex: -1,
    };
  },
  methods: {
    parentClick(index) {
      if (this.classList[index].status) {
        this.classList[index].status = false;
      } else {
        this.classList[index].status = true;
      }
      this.detailStatus = false;
      this.$forceUpdate();
    },
    changePage(num) {
      this.p = num;
      let id = "";
      for (let i = 0; i < this.classList.length; i++) {
        for (let j = 0; j < this.classList[i].son.length; j++) {
          if (this.classList[i].son[j].status) {
            id = this.classList[i].son[j].id;
          }
        }
      }
      this.get_item_list(id);
    },
    parentPClick(index) {
      for (let i = 0; i < this.classList.length; i++) {
        this.classList[i].status = false;
      }
      this.classList[index].status = true;
      this.tablePindex = index;
      this.detailStatus = false;
      this.$forceUpdate();
    },
    childrenClick(index, indexS) {
      for (let i = 0; i < this.classList.length; i++) {
        for (let j = 0; j < this.classList[i].son.length; j++) {
          this.classList[i].son[j].status = false;
        }
      }
      this.p = 1;
      this.get_item_list(this.classList[index].son[indexS].id);
      this.classList[index].son[indexS].status = true;
      this.detailStatus = false;
      this.$forceUpdate();
    },
    childrenPClick(id, index) {
      this.detailStatus = false;
      this.childPindex = index;
      this.get_item_list(id);
    },
    get_item_list(id) {
      get_item_list(qs.stringify({ p: this.p, cate_id: id })).then((res) => {
        if (res.data.code == 1) {
          this.dataList = res.data.data.data;
          this.total = res.data.data.last_page;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    detailClick(id) {
      if (this.detailStatus) {
        this.detailStatus = false;
      } else {
        get_item_info(qs.stringify({ item_id: id })).then((res) => {
          if (res.data.code == 1) {
            this.detailStatus = true;
            this.dataInfo = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    get_item_category() {
      get_item_category().then((res) => {
        if (res.data.code == 1) {
          this.get_item_list(res.data.data[0].son[0].id);
          this.classList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.get_item_category();
    if (this.$route.query.id) {
      this.detailClick(this.$route.query.id);
    }
    this.backTop();
  },
};
</script>

<style scoped>
.detailP {
  position: relative;
  padding: 30px 15px 15px 15px;
}
.detailP_back {
  font-size: 20px;
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.detailP_image {
  width: 70%;
  display: block;
  margin: 0 auto;
}
.detailP_title {
  font-weight: bold;
  font-size: 30px;
  color: #333;
  padding: 30px 0 20px 0;
}
.classP {
  position: relative;
  padding: 10px 15px;
  background: #f6f6f6;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
}
.classP_table {
  height: 32px;
  padding: 0 10px;
  margin-right: 10px;
  font-size: 14px;
  color: #333;
  border-radius: 16px;
}
.classP_active {
  color: #fff;
  background: #004e9f;
}
.moduleP {
  padding: 15px;
  background: #fff;
}
.moduleP_item {
  position: relative;
  width: 48.5%;
  margin-right: 3%;
  margin-bottom: 15px;
  cursor: pointer;
}
.moduleP_item:nth-child(2n) {
  margin-right: 0;
}
.moduleP_image {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #f0f0f0;
  display: block;
}
.moduleP_footer {
  font-size: 17px;
  color: #333;
  padding: 15px;
  text-align: center;
  overflow: hidden;
  background: #f0f0f0;
}
.childP {
  position: relative;
}
.childP_item {
  padding: 10px;
  background: #fff;
  margin: 5px 5px 0 0;
}
.childP_active {
  color: #004e9f;
}
.module {
  padding: 50px 0;
  width: 1200px;
  margin: 0 auto;
}
.module_class {
  position: relative;
  width: 220px;
  margin-right: 55px;
}
.module_header {
  position: relative;
  width: 220px;
  height: 85px;
}
.module_back {
  position: absolute;
  height: 100%;
  width: 100%;
}
.module_data {
  position: relative;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
}
.module_title {
  font-size: 17px;
  color: #fff;
}
.module_en {
  font-size: 13px;
  color: #fff;
}
.module_text {
  padding: 15px;
  background: #f6f6f6;
  font-size: 14px;
  cursor: pointer;
  color: #333;
}
.module_child {
  padding: 15px;
  background: #f6f6f6;
  font-size: 14px;
  cursor: pointer;
  color: #333;
}
.module_active {
  background: #e8e8e8;
  color: #004e9f;
}

.module_item {
  width: 280px;
  margin-right: 25px;
  margin-bottom: 30px;
  cursor: pointer;
}
.module_item:nth-child(3n) {
  margin-right: 0;
}
.module_image {
  width: 280px;
  height: 280px;
  box-sizing: border-box;
  border: 1px solid #fff;
  display: block;
}
.module_footer {
  font-size: 17px;
  color: #333;
  padding: 25px 15px;
  overflow: hidden;
  background: #fff;
}
.module_item:hover .module_image {
  border: 1px solid #004e9f;
}
.module_item:hover .module_footer {
  color: #fff;
  background: #004e9f;
}

.detail {
  position: relative;
  padding-left: 50px;
}
.detail_back {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.detail_image {
  width: 400px;
  height: 400px;
  margin-right: 55px;
}
.detail_title {
  font-weight: bold;
  font-size: 44px;
  color: #333;
  padding-bottom: 30px;
}
.detail_text {
  font-size: 17px;
  padding-bottom: 15px;
}
.detail_text:last-child {
  padding-bottom: 0;
}

.page {
  padding: 30px;
}
.page_item {
  height: 45px;
  width: 45px;
  color: #fff;
  font-size: 17px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  background: #ddd;
}
.page_active {
  background: #004e9f;
}
</style>